<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-7">
        <b-card title="Certificate Format Lists" no-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Background</th>
                <th>Information</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <img :src="format.background_file" width="300" class="rounded"/>
                </td>
                <td>
                  <p class="mb-0">
                    <b>{{ format.name }}</b>
                  </p>
                  <p class="mb-0"><b>Created by:</b> {{ format.created_by_name }}</p>
                  <p class="mb-0">
                    <b>Background Name : </b> {{ format.background_name }}
                  </p>
                  <p class="mb-0">
                      <span
                          style="margin-right: 3px"
                          class="badge"
                          :class="
                        format.status == 'Active'
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                      >
                      {{ format.status }}
                    </span>
                    <span
                        class="badge"
                        :class="
                        format.is_header == 'With header'
                          ? 'badge-success'
                          : 'badge-danger'
                        "
                    >
                        {{ format.is_header }}
                      </span>
                  </p>
                </td>
                <td>
                  <Tooltip content = "Generate" placement = "top-end">
                    <router-link
                        v-if="permission.attach_manage"
                        class="btn btn-primary btn-sm"
                        :to="'/certificate/generator/' + format.id"
                        style="margin-left: 5px"
                    >
                      <feather-icon icon="ListIcon" size="16"/>
                    </router-link>
                  </Tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>
      <div class="col-md-5">
        <b-card title="Certificate Format Lists" no-body>
          <b-card-body>
            <b-row class="justify-content-center" v-if="permission.attach_manage">
              <b-col cols="10">
                <div class="form-group">
                  <label>Select Meta Data</label>
                  <Select
                      v-model="metaDataForm.meta_data_id"
                      multiple
                      filterable>
                  <Option
                      v-for="(data, index) in allMetaData"
                      :value="data.id"
                      :key="data.id"
                  >{{ data.name }}
                  </Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="2" v-if="permission.attach_manage">
                <b-button
                    variant="primary"
                    @click="addMetaData()"
                    class="mt-2"
                >
                  Add
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="text-center">Sl</th>
                <th>Name</th>
                <th>Status</th>
                <th>Remove</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(metaData, index) in formatMetaData"
                  :key="index"
                  v-if="formatMetaData.length"
              >
                <td class="align-middle text-center">
                  {{ index + 1 }}
                </td>
                <td>
                  <p class="mb-0">
                    <b>{{ metaData.name }}</b>
                  </p>
                  <p class="mb-0"><b>Slug:</b> {{ metaData.slug }}</p>
                  <p class="mb-0"><b>Data Type:</b> {{ metaData.data_type }}</p>
                  <p class="mb-0"><b>Type:</b> {{ metaData.type }}</p>
                </td>
                <td>
                  <p class="mb-0">
                      <span
                          style="margin-right: 3px"
                          class="badge"
                          :class="
                        metaData.is_required == 'Required'
                          ? 'badge-success'
                          : 'badge-info'
                      "
                      >
                      {{ metaData.is_required }}
                    </span>
                    <span
                        class="badge"
                        :class="
                        metaData.is_unique == 'Unique'
                          ? 'badge-success'
                          : 'badge-info'
                        "
                    >
                        {{ metaData.is_unique }}
                      </span>
                  </p>
                </td>
                <td>
                  <p class="mb-0" v-if="permission.attach_manage">
                    <button class="btn btn-danger btn-sm"

                            variant="danger"
                            @click="deleteMetaData(metaData.id)"
                    >
                      <feather-icon icon="DeleteIcon" size="15"></feather-icon>
                    </button>
                  </p>
                </td>
              </tr>
              <tr
                  v-if="formatMetaData.length == 0">
                <td class="text-center" colspan="4">
                  <p class="font-weight-bolder">No data found !</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        meta_data_id: null,
      }),
      metaDataForm: new Form({
        meta_data_id: [],
      }),
      id: this.$route.params.format_id,
      format: {},
      allMetaData: {},
      formatMetaData: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getFormat();
    this.getMetaData();
    this.getAllMetaData();
  },
  methods: {
    getFormat() {
      axios
          .get('/app/certificate-format/' + this.id)
          .then((res) => {
            this.format = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getAllMetaData() {
      axios
          .get('/app/certificate/all/meta-data')
          .then((res) => {
            this.allMetaData = res.data.meta_data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getMetaData() {
      axios
          .get('/app/certificate-format/meta-data/' + this.id)
          .then((res) => {
            this.formatMetaData = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    addMetaData() {
      this.metaDataForm
          .post('/app/certificate/attach/meta-data/' + this.id)
          .then((res) => {
            this.getMetaData();
            this.clear();
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    deleteMetaData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.meta_data_id = id;
          this.form
              .delete('/app/certificate/detach/meta-data/' + this.id)
              .then((res) => {
                this.getMetaData();
                this.s(res.data.message);
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },

    clear() {
      this.form.meta_data_id = null;
      this.metaDataForm.meta_data_id = [];
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.format;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
